const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/dashboard/Dashboard"),
        meta: { login_required: true }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/settings/Settings"),
        meta: { admin_route: true }
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/user/Edit"),
        meta: { login_required: true }
    },
    {
        path: "/users",
        name: "user.list",
        component: () => import("@/views/user/List"),
        meta: { admin_route: true, scroll_position: true }
    },
    {
        path: "/user/:id?",
        name: "user.edit",
        component: () => import("@/views/user/Edit"),
        meta: { admin_route: true }
    },
    {
        path: "/clients",
        name: "client.list",
        component: () => import("@/views/client/List"),
        meta: { login_required: true, scroll_position: true }
    },
    {
        path: "/client/:id?",
        name: "client.edit",
        component: () => import("@/views/client/Edit"),
        meta: { login_required: true }
    },
    {
        path: "/service_repair_orders",
        name: "service_repair_order.list",
        component: () => import("@/views/sro/List"),
        meta: { login_required: true }
    },
    {
        path: "/service_repair_order/:id?",
        name: "service_repair_order.edit",
        component: () => import("@/views/sro/Edit"),
        meta: { login_required: true }
    },
    {
        path: "/reports",
        name: "reports",
        component: () => import("@/views/report/List"),
        meta: { login_required: true }
    },
    {
        path: "/report/:id",
        name: "report.view",
        component: () => import("@/views/report/View"),
        meta: { login_required: false }
    }
]

export default routes
